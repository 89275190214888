<template>
  <div class="center">
    <button @click="editItem(row)" type="button" class="btn btn-icon" title="Edit Plan">
      <i class="fa fa-edit"></i>
    </button>

    <button @click="deleteItem(row)" type="button" class="btn btn-icon" title="Delete Plan">
      <i class="fa fa-trash"></i>
    </button>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested', 'xprops'],

  data() {
    return {
      disableArchive: false,
    }
  },

  mounted() {

  },

  methods: {
    deleteItem(plan) {
      this.xprops.eventbus.$emit('delete', plan)
    },
    editItem(plan) {
      this.xprops.eventbus.$emit('edit', plan)
    },
  },
}
</script>

<style scoped lang="scss">
button {
  i {
    font-size: 1.6rem;
  }
}
</style>
